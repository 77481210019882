const actions = {
  SET_DEPOSIT: 'SET_DEPOSIT',
  SET_WITHDRAW: 'SET_WITHDRAW',
  SET_TRANSFER: 'SET_TRANSFER',
  SET_ACCOUNT_WITHDRAW: 'SET_ACCOUNT_WITHDRAW',
  SET_ACCOUNT_TRANSFER: 'SET_ACCOUNT_TRANSFER',
  SET_VERIFICATION: 'SET_VERIFICATION',
  SET_BANK: 'SET_BANK',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  setDeposit: (deposit) => {
    return {
      type: actions.SET_DEPOSIT,
      deposit: deposit
    }
  },
  setWithdraw: (withdraw) => {
    return {
      type: actions.SET_WITHDRAW,
      withdraw: withdraw
    }
  },
  setTransfer: (transfer) => {
    return {
      type: actions.SET_TRANSFER,
      transfer: transfer
    }
  },
  setAccountWithdraw: (account_withdraw) => {
    return {
      type: actions.SET_ACCOUNT_WITHDRAW,
      account_withdraw: account_withdraw
    }
  },
  setAccountTransfer: (account_transfer) => {
    return {
      type: actions.SET_ACCOUNT_TRANSFER,
      account_transfer: account_transfer
    }
  },
  setVerification: (verification) => {
    return {
      type: actions.SET_VERIFICATION,
      verification: verification
    }
  },
  setBank: (bank) => {
    return {
      type: actions.SET_BANK,
      bank: bank
    }
  },
  setNotification: (notification) => {
    return {
      type: actions.SET_NOTIFICATION,
      notification: notification
    }
  },
}
export default actions
