import actions from "./actions"

const initState = {
  modalActive: false,
  selected: null,
  refresh: null,
}

export default function (state = initState, action) {
  switch (action.type) {
    case actions.TOGGLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
      }
    case actions.SET_MODAL_ACTIVE:
      // if modal inactive, set selected to null
      let selected = state.selected
      if (!action.modalActive) {
        selected = null
      }

      return {
        ...state,
        selected,
        modalActive: action.modalActive,
      }
    case actions.SET_SELECTED:
      // if selected is not null, set modal to active
      let modalActive = state.modalActive
      if (action.selected != null) {
        modalActive = true
      }

      return {
        ...state,
        modalActive,
        selected: action.selected,
      }
    case actions.SET_REFRESH:
      return {
        ...state,
        refresh: action.refresh,
      }
    default:
      return state
  }
}
