const actions = {
  TOGGLE_MODAL: "TOGGLE_MODAL",
  SET_MODAL_ACTIVE: "SET_MODAL_ACTIVE",
  SET_SELECTED: "SET_SELECTED",
  SET_REFRESH: "SET_REFRESH",
  toggleModal: () => ({
    type: actions.TOGGLE_MODAL,
  }),
  setModalActive: (isActive) => {
    return {
      type: actions.SET_MODAL_ACTIVE,
      modalActive: isActive,
    }
  },
  setSelected: (selected) => {
    return {
      type: actions.SET_SELECTED,
      selected: selected,
    }
  },
  setRefresh: (refresh) => {
    return {
      type: actions.SET_REFRESH,
      refresh: refresh,
    }
  },
}
export default actions
