import actions from './actions'

const initState = {
  deposit: 0,
  withdraw: 0,
  transfer: 0,
  account_withdraw: 0,
  account_transfer: 0,
  verification: 0,
  bank: 0
}

export default function (state = initState, action) {
  switch (action.type) {
    case actions.SET_DEPOSIT:
      return {
        ...state,
        deposit: action.deposit
      }
    case actions.SET_WITHDRAW:
      return {
        ...state,
        withdraw: action.withdraw
      }
    case actions.SET_TRANSFER:
      return {
        ...state,
        transfer: action.transfer
      }
    case actions.SET_ACCOUNT_WITHDRAW:
      return {
        ...state,
        account_withdraw: action.account_withdraw
      }
    case actions.SET_ACCOUNT_TRANSFER:
      return {
        ...state,
        account_transfer: action.account_transfer
      }
    case actions.SET_VERIFICATION:
      return {
        ...state,
        verification: action.verification
      }
    case actions.SET_BANK:
      return {
        ...state,
        bank: action.bank
      }
    case actions.SET_NOTIFICATION:
      console.log(action.notification)
      
      return {
        ...state,
        ...action.notification
      }
    default:
      return state
  }
}
