export default {
  enabled: false,
  // baseUrl: 'https://tradehall.gaincue.com/api/v1/admin',
  // baseUrl: 'http://localhost:3000/api/v1/admin',
  // baseUrl: 'https://ml-api.tradehall.co/api/v1/admin',
  // baseUrl: "https://dev-tfapi.tradersflow.co/api/v1/admin",
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  baseUrlUser: process.env.REACT_APP_API_BASE_URL_USER,
  imageUrl: process.env.REACT_APP_API_IMAGE_URL,  
  secretKey: 'secretKey'
}
