import { all, takeEvery, put, fork } from "redux-saga/effects"
// import { createBrowserHistory } from "history"

import {
  getToken,
  getProfile,
  clearToken,
  clearProfile,
} from "@iso/lib/helpers/utility"
import actions from "./actions"

// const history = createBrowserHistory()
const fakeApiCall = false // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    const { profile, token } = payload

    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        profile: profile,
      })
    } else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: "secret token",
          profile: "Profile",
        })
      } else {
        yield put({ type: actions.LOGIN_ERROR })
      }
    }
  })
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem("token", payload.token)
    yield localStorage.setItem("profile", JSON.stringify(payload.profile))
  })
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {})
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    clearToken()
    clearProfile()
    // yield put(history.push("/"))
  })
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("token")
    const profile = getProfile().get("profile")

    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile,
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ])
}
